.group{@apply flex
      flex-shrink-0
      items-center
      justify-between
      border-b
      border-[#EFEFF9]
      pb-[8px]
      text-[16px]
      font-medium
      leading-[19px]
      text-[#171717]
      last:border-b-0
      max-sm:text-[14px]
      max-sm:leading-[16px]}.group_active .link,.group_active .actions{@apply bg-[#4671B0] text-[#FFFFFF]}.group .link,.group .actions{@apply flex h-[38px] items-center transition-colors ease-linear}.group .link{@apply grow truncate rounded-s-[6px] pl-[15px]}.group .actions{@apply gap-[9px] rounded-e-[6px] pr-[15px]}.group:not(.group_active):hover .link,.group:not(.group_active):hover .actions{@apply bg-[#F0F5FC]}.group:not(.group_active):hover .link:where(:global(.dark),:global(.dark) *),.group:not(.group_active):hover .actions:where(:global(.dark),:global(.dark) *){@apply bg-[#36373B]}.group:not(.group_active):where(:global(.dark),:global(.dark) *){@apply text-[#A3ABB6]}.group:where(:global(.dark),:global(.dark) *){@apply border-[#242527]}